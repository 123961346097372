import React, { useEffect, useState }  from "react";
import BookListHorizontal from "./BookListHorizontal";
import FeaturedBookCarousel from "./FeaturedBookCarousel";
import TopCharacters from "./TopCharacters";
import HeroSection from "./HeroSection";
import { useNavigate } from 'react-router-dom';
import { catchError, authFetch } from '../utils.js';
import { useTheme } from '../context/ThemeContext';
import { useAuth } from '../context/AuthContext';

export default function HomePage() {
  const [books, setBooks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [topCharacters, setTopCharacters] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('Fantasy');
  const { isDarkMode } = useTheme();
  const { user, loading: authLoading } = useAuth();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      // Fetch books
      const [booksError, booksData] = await catchError(authFetch({ path: `/books`, requireAuth: false }));
      if (booksData) {
        setBooks(booksData);
        // Extract unique categories from books
        const uniqueCategories = [...new Set(booksData.flatMap(book => book.categories || []))];
        setCategories(uniqueCategories);
      }
      if (booksError) console.error('Error fetching books:', booksError);

      // Fetch top characters
      const [charsError, charsData] = await catchError(authFetch({ path: `/characters/top`, requireAuth: false }));
      if (charsData) {
        setTopCharacters(charsData);
      }
      if (charsError) console.error('Error fetching top characters:', charsError);

      setLoading(false);
    };
  
    fetchData();
  }, []);

  const handleImageClick = (id) => {
    navigate(`/books/${id}`);
  }

  const handleContinueReading = (id, currentPage) => {
    navigate(`/books/${id}/page/${currentPage}`);
  }

  // Get books by category
  const getBooksByCategory = (category) => {
    return books.filter(book => book.categories?.includes(category));
  }

  return (
    <div className="main-container">
      { (loading || authLoading) ? (
        <div className={`loading-screen ${isDarkMode ? 'dark' : 'light'}`}>
          <div className="loading-content">
            <img src={isDarkMode ? "book-logo-dark.png" : "book-logo-light.png"} alt="Book Alchemy" className="hero-logo" style={{width: '150px'}}/>
            <h2>Loading library...</h2>
            <p>Preparing your personalized reading experience</p>
          </div>
        </div>
      ) : (
        <>
          {/* Hero Section */}
          <HeroSection books={books} />

          {/* Featured Carousel */}
          {books?.length > 0 && (
            <FeaturedBookCarousel 
              books={books.slice(0, 5)} 
              handleClick={handleImageClick}
              user={user}
            />
          )}

          {/* Top Characters Section */}
          {topCharacters?.length > 0 && (
            <TopCharacters characters={topCharacters} />
          )}
          { books?.length > 0 && 
            <div className="books-section popular-now">
              <BookListHorizontal 
                title={"Popular Now"} 
                books={books.slice(5, 10)} 
                handleClick={handleImageClick}
                handleAction={handleContinueReading}
              /> 
            </div>
          }
          {/* Categories Section */}
          <div className="categories-section">
            <h2>Explore Categories</h2>
            <div className="categories-nav">
              {categories.slice(0, 8).map((category) => (
                <button
                  key={category}
                  className={`category-btn ${selectedCategory === category ? 'active' : ''}`}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </button>
              ))}
            </div>
            
            {selectedCategory === 'all' ? (
              // Show all categories when 'all' is selected
              categories.map((category) => {
                const categoryBooks = getBooksByCategory(category);
                return categoryBooks.length > 0 ? (
                  <div key={category} className={`books-section category-${category.toLowerCase().replace(/\s+/g, '-')}`}>
                    <BookListHorizontal 
                      title={category}
                      books={categoryBooks}
                      handleClick={handleImageClick}
                      handleAction={handleContinueReading}
                    />
                  </div>
                ) : null;
              })
            ) : (
              // Show only the selected category's books
              <div className={`books-section category-${selectedCategory.toLowerCase().replace(/\s+/g, '-')}`}>
                <BookListHorizontal 
                  title={selectedCategory}
                  books={getBooksByCategory(selectedCategory)}
                  handleClick={handleImageClick}
                  handleAction={handleContinueReading}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}