import React, { useState, useEffect, useRef } from "react";
import { ArrowLeft, Search } from "react-feather";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useTheme } from '../context/ThemeContext';
import { authFetch, catchError } from '../utils';

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkMode } = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const searchRef = useRef(null);

  // Only show back button if we're not at the root path
  const isSearchPage = location.pathname === '/search';

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?query=${encodeURIComponent(searchQuery.trim())}`);
      setShowDropdown(false);
    }
  };

  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    
    if (query.trim().length >= 2) {
      setIsSearching(true);
      setShowDropdown(true);
      
      try {
        const [error, data] = await catchError(authFetch({
          path: `/books/search?query=${encodeURIComponent(query.trim())}`,
          method: 'GET'
        }));

        if (data && data.books) {
          setSearchResults(data.books.slice(0, 5)); // Show only first 5 results
        }
      } catch (error) {
        console.error('Search error:', error);
      } finally {
        setIsSearching(false);
      }
    } else {
      setSearchResults([]);
      setShowDropdown(false);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleResultClick = (bookId) => {
    navigate(`/books/${bookId}`);
    setShowDropdown(false);
    setSearchQuery('');
  };

  return (
    <div className={`app-header ${isDarkMode ? 'dark' : 'light'}`}>
      <div className="header-content">
        <NavLink className="book-header-title" style={{display: 'flex'}} to="/">
          <img src={isDarkMode ? "book-logo-dark.png" : "book-logo-light.png"} alt="Book Alchemy" />
          <h1>Chatty Books</h1>
          {/* <img src={isDarkMode ? "app-name-dark.png" : "app-name-light.png"} alt="Book Alchemy" style={{width: '200px'}} /> */}
        </NavLink>
        {!isSearchPage && (
          <div className="header-search desktop-only" ref={searchRef}>
            <form onSubmit={handleSearch}>
              <div className={`search-input-container ${isDarkMode ? 'dark' : 'light'}`}>
                <Search size={20} />
                <input
                  type="text"
                  placeholder="Search books..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className={`search-input ${isDarkMode ? 'dark' : 'light'}`}
                />
              </div>
            </form>
            {showDropdown && (
              <div className={`search-dropdown ${isDarkMode ? 'dark' : 'light'}`}>
                {isSearching ? (
                  <div className="search-loading">
                    <div className="loading-spinner" />
                  </div>
                ) : searchResults.length > 0 ? (
                  <>
                    {searchResults.map((book) => (
                      <div
                        key={book._id}
                        className="search-result-item"
                        onClick={() => handleResultClick(book._id)}
                      >
                        <div className="search-result-image">
                          <img 
                            src={book.image_url || '/placeholder-book.png'} 
                            alt={book.title}
                            onError={(e) => {
                              e.target.src = '/placeholder-book.png';
                            }}
                          />
                        </div>
                        <div className="search-result-info">
                          <h4>{book.title}</h4>
                          <p>{book.author}</p>
                        </div>
                      </div>
                    ))}
                    <div 
                      className="view-all-results"
                      onClick={handleSearch}
                    >
                      View all results
                    </div>
                  </>
                ) : searchQuery.trim().length >= 2 ? (
                  <div className="no-results">No books found</div>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}