import React, { useRef, useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from 'react-feather';

export default function BookListHorizontal({ books, title, handleClick, handleAction }) {
  const scrollContainerRef = useRef(null);
  const [showLeftIndicator, setShowLeftIndicator] = useState(false);
  const [showRightIndicator, setShowRightIndicator] = useState(false);

  const checkScrollIndicators = () => {
    const container = scrollContainerRef.current;
    if (container) {
      setShowLeftIndicator(container.scrollLeft > 0);
      setShowRightIndicator(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  };

  useEffect(() => {
    checkScrollIndicators();
  }, [books]);

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div>
      <h1 style={{marginLeft: '20px'}} className="lrg-font">{title}</h1>
      <div className="book-list-container">
        {showLeftIndicator && (
          <button 
            className="scroll-button left"
            onClick={() => scroll('left')}
          >
            <ChevronLeft size={24} />
          </button>
        )}
        {showRightIndicator && (
          <button 
            className="scroll-button right"
            onClick={() => scroll('right')}
          >
            <ChevronRight size={24} />
          </button>
        )}
        <div 
          className="book-list" 
          ref={scrollContainerRef}
          onScroll={checkScrollIndicators}
        >
          {books.map((book, index) => (
            <div key={index} className="book-img-label">
              <div 
                className="book-img-container"
                onClick={() => handleClick(book._id)}
                style={{ cursor: 'pointer' }}
              >
                <img
                  className="book-img"
                  key={index}
                  src={book.image_url}
                  alt={'book cover for ' + book.title}
                />
                <div className="book-info">
                  <h3>{book.title}</h3>
                  <h4>{book.author || 'John Doe'}</h4>
                  {book.categories && book.categories.length > 0 && (
                    <div className="book-categories-horizontal">
                      <span className="category-tag-small">{book.categories[0]}</span>
                    </div>
                  )}
                </div>
              </div>
              {handleAction && book.currentPage > 1 && (
                <button 
                  className="submit-btn" 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(book._id, book.currentPage);
                  }}
                >
                  Continue Reading
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}