import React, { useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'react-feather';
import CharacterModal from './CharacterModal';
import { useTheme } from '../context/ThemeContext';

export default function TopCharacters({ characters }) {
  const scrollContainerRef = useRef(null);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const { isDarkMode } = useTheme();

  const handleCharacterClick = (character) => {
    setSelectedCharacter(character);
  };

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === 'left' ? -400 : 400;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="top-characters-section">
      <div className="section-header">
        <div className="section-header">
          <h2>Popular Characters</h2>
        </div>
        <p className="section-description">
          Meet our most engaging characters, ranked by conversation count. Dive into meaningful conversations with literary figures. Each character brings their unique perspective and story to life through interactive dialogue.
        </p>
      </div>
      
      <div className="top-characters-scroll-container">
        <button 
          className="scroll-button left" 
          onClick={() => scroll('left')}
          aria-label="Scroll left"
        >
          <ChevronLeft size={24} />
        </button>

        <div className="top-characters-grid" ref={scrollContainerRef}>
          {characters.map((character) => (
            <div 
              key={character._id} 
              className="character-card"
              onClick={() => handleCharacterClick(character)}
            >
              <div className="character-image">
                {character.image_url ? (
                  <img src={character.image_url} alt={`${character.firstName} ${character.lastName}`} />
                ) : (
                  <div className="character-placeholder">
                    {character.firstName[0]}{character.lastName[0]}
                  </div>
                )}
              </div>
              <div className="character-info">
                <h3>{character.firstName} {character.lastName}</h3>
                <p className="character-book">{character.bookTitle}</p>
                <p className="character-description">{character.description}</p>
                {/* <div className="character-stats">
                  <MessageCircle size={16} />
                  <span>{character.messageCount} messages</span>
                </div> */}
              </div>
            </div>
          ))}
        </div>

        <button 
          className="scroll-button right" 
          onClick={() => scroll('right')}
          aria-label="Scroll right"
        >
          <ChevronRight size={24} />
        </button>
      </div>
      <Link to="/characters" className="view-more-link">View More</Link>

      {selectedCharacter && (
        <CharacterModal
          character={selectedCharacter}
          onClose={() => setSelectedCharacter(null)}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
} 