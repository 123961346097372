import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/footer.css';

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>Company</h4>
          <ul>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/help">Help Center</Link></li>
          </ul>
        </div>
        
        <div className="footer-section">
          <h4>Legal</h4>
          <ul>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><Link to="/terms">Terms of Service</Link></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Powered by</h4>
          <div className="partner-logos">
            <img src="/gemini-logo.png" alt="Google Gemini" />
          </div>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p>© 2025 Topaz Innovations Labs Inc. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer; 