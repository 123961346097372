import React, { useState, useEffect, lazy, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ThemeProvider, useTheme } from './context/ThemeContext';
import { AuthProvider } from './context/AuthContext';
import { TokenProvider } from './context/TokenContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Styles
import './App.css'
import './styles/index.css'
import './styles/settings.css'
import './styles/chat.css'
import './styles/character.css'
import './styles/nav.css'
import './styles/book.css'
import './styles/bookReader.css'
import './styles/page.css'
import './styles/featured.css'
import './styles/file.css'
import './styles/search.css'
import './styles/bookUpload.css'
import './styles/tokenCosts.css'
import './styles/booksPage.css'
import './styles/toast.css'
import './styles/TokenTransactionsDashboard.css'

// Core components that should load immediately
import Navbar from "./components/navbar";
import Header from "./components/header";
import Footer from "./components/Footer";
import HomePage from "./components/homePage";

// Lazy loaded components
const RegisterUser = lazy(() => import('./components/registerUser'));
const LoginUser = lazy(() => import('./components/loginUser'));
const EmailVerification = lazy(() => import('./components/EmailVerification'));
const ForgotPassword = lazy(() => import('./components/ForgotPassword'));
const ResetPassword = lazy(() => import('./components/ResetPassword'));
const SettingsPage = lazy(() => import('./components/settingsPage'));
const BooksPage = lazy(() => import('./components/booksPage'));
const BookPage = lazy(() => import('./components/bookPage'));
const BookReader = lazy(() => import('./components/bookReader'));
const CharacterSelect = lazy(() => import('./components/charaterSelect'));
const CharacterList = lazy(() => import('./components/characterList'));
const CharacterChat = lazy(() => import('./components/characterChat'));
const SearchBooks = lazy(() => import('./components/searchBooks'));
const BookUpload = lazy(() => import('./components/bookUpload'));
const EditProfile = lazy(() => import('./components/editProfile'));

// Admin components (lazy loaded)
const AdminBooksPage = lazy(() => import('./components/adminBooksPage'));
const NewBookPage = lazy(() => import('./components/newBookPage'));
const AdminBookPage = lazy(() => import('./components/adminBookPage'));
const EditBookPage = lazy(() => import('./components/editBookPage'));
const AdminPagesPage = lazy(() => import('./components/adminPagesPage'));
const AdminCharactersPage = lazy(() => import('./components/adminCharacters.js'));
const AdminSubscriptionsPage = lazy(() => import('./components/adminSubscriptions.js'));
const TokenCostManager = lazy(() => import('./components/TokenCostManager'));
const TokenTransactionsDashboard = lazy(() => import('./components/TokenTransactionsDashboard'));
const CostAnalysisPage = lazy(() => import('./components/CostAnalysisPage'));
const AdminAnalytics = lazy(() => import('./components/AdminAnalytics'));

// Static pages (lazy loaded)
const HelpCenter = lazy(() => import('./components/HelpCenter'));
const AboutUs = lazy(() => import('./components/AboutUs'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./components/TermsOfService'));

const AppContent = () => {
  const location = useLocation();
  const { isDarkMode } = useTheme();
  const [layoutState, setLayoutState] = useState({
    showHeader: true,
    showNavBar: true,
    showSettings: false,
    showFooter: true
  });

  // Memoize the route checking function
  const checkRoute = React.useCallback((pathname) => {
    const isHiddenHeader = ['/login', '/register', '/verify-email', '/forgot-password', '/reset-password', '/page', '/view/characters']
      .some(route => pathname.includes(route));
    
    const isShowSettings = ['/settings', '/admin/books']
      .some(route => pathname.startsWith(route));
    
    const isHiddenFooter = ['/admin', '/login', '/register', '/verify-email', '/forgot-password', '/reset-password', '/page', '/view/characters', '/characters']
      .some(route => pathname.startsWith(route)) ||
      pathname.includes('/page/') ||
      pathname.includes('/view/characters/');

    return {
      showHeader: !isHiddenHeader,
      showNavBar: true, // since hiddenNavbarRoutes is empty
      showSettings: isShowSettings,
      showFooter: !isHiddenFooter
    };
  }, []);

  // Update layout state on route change
  useEffect(() => {
    setLayoutState(checkRoute(location.pathname));

    // Store previous route only when needed
    if (!location.pathname.includes('/login') && !location.pathname.includes('/register')) {
      localStorage.setItem('previousRoute', location.pathname);
    }
  }, [location.pathname, checkRoute]);

  return (
    <div>
      <ToastContainer 
        position="bottom-right"
        autoClose={3000}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={isDarkMode ? "dark" : "light"}
        transition={Slide}
        limit={3}
      />
      {layoutState.showHeader && <Header />}
      {layoutState.showNavBar && (
        <Navbar 
          setShowHeader={val => setLayoutState(prev => ({...prev, showHeader: val}))}
          setShowNavBar={val => setLayoutState(prev => ({...prev, showNavBar: val}))}
          setShowSettings={val => setLayoutState(prev => ({...prev, showSettings: val}))}
        />
      )}
      <Suspense fallback={
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      }>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/register" element={<RegisterUser />} />
          <Route path="/login" element={<LoginUser />} />
          <Route path="/verify-email" element={<EmailVerification />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/books" element={<BooksPage />} />
          <Route path="/search" element={<SearchBooks />} />
          <Route path="/upload" element={<BookUpload />} />
          <Route path="/books/:bookId" element={<BookPage />} />
          <Route path="/books/:bookId/page/:pageNumber" element={<BookReader />} />
          <Route path="/characters" element={<CharacterList />} />
          <Route path="/books/:bookId/view/characters" element={<CharacterSelect />} />
          <Route path="/books/:bookId/view/characters/:characterId" element={<CharacterChat />} />
          <Route path="/settings/edit-profile" element={<EditProfile />} />
          
          {/* Admin Routes */}
          <Route path="/admin/transactions" element={<TokenTransactionsDashboard />} />
          <Route path="/admin/cost-analysis" element={<CostAnalysisPage />} />
          <Route path="/admin/analytics" element={<AdminAnalytics />} />
          <Route path="/admin/books" element={<AdminBooksPage />} />
          <Route path="/admin/new/book" element={<NewBookPage />} />
          <Route path="/admin/books/:bookId" element={<AdminBookPage />} />
          <Route path="/admin/books/:bookId/edit" element={<EditBookPage />} />
          <Route path="/admin/books/:bookId/edit/pages" element={<AdminPagesPage />} />
          <Route path="/admin/books/:bookId/edit/characters" element={<AdminCharactersPage />} />
          <Route path="/admin/subscriptions" element={<AdminSubscriptionsPage />} />
          <Route path="/admin/token-costs" element={<TokenCostManager />} />
          
          {/* Static Pages */}
          <Route path="/help" element={<HelpCenter />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfService />} />
        </Routes>
      </Suspense>
      {layoutState.showFooter && <Footer />}
    </div>
  );
};

const App = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <ThemeProvider>
        <AuthProvider>
          <TokenProvider>
            <AppContent />
          </TokenProvider>
        </AuthProvider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
};

export default App;