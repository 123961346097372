import React from 'react';
import { Book, MessageCircle, Image, Headphones, BookOpen, Zap } from 'react-feather';
import { useTheme } from '../context/ThemeContext';
import { useNavigate } from 'react-router-dom';

const HeroSection = ({ books = [] }) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const features = [
    {
      icon: <Zap className="feature-icon-svg" />,
      title: "AI-Powered Reading",
      description: "Transform your reading with AI features"
    },
    {
      icon: <MessageCircle className="feature-icon-svg" />,
      title: "Character Chat",
      description: "Have meaningful conversations with your favorite characters"
    },
    {
      icon: <Image className="feature-icon-svg" />,
      title: "Scene Visualization",
      description: "Generate stunning images from any scene in your books"
    },
    {
      icon: <Headphones className="feature-icon-svg" />,
      title: "Audio Experience",
      description: "Listen to narrated versions of your favorite stories"
    }
  ];

  // Select more books for the floating effect
  const displayBooks = books.slice(0, 8);

  return (
    <div className={`hero-section ${isDarkMode ? 'dark' : 'light'}`}>
      <div className="hero-background">
        {displayBooks.map((book, index) => (
          <div 
            key={index} 
            className="floating-book"
            style={{
              backgroundImage: `url(${book.image_url})`,
              animationDelay: `${index * 0.3}s`,
              '--rotation': `${(index % 2 === 0 ? -1 : 1) * (Math.random() * 3 + 2)}deg`
            }}
          />
        ))}
      </div>

      <div className="hero-content">
        <div className="logo-container">
          <img src={isDarkMode ? "book-logo-dark.png" : "book-logo-light.png"} alt="Book Alchemy" className="hero-logo" />
        </div>
        <div className="hero-text">
          <h1>Bring Books to Life</h1>
          <p className="hero-subtitle">
            Experience the future of reading with AI-powered features that make your stories interactive and immersive
          </p>
          <div className="cta-buttons">
            <button 
              className="primary-cta"
              onClick={() => navigate('/search')}
            >
              Explore Library
            </button>
          </div>
        </div>
        
        <div className="features-grid">
          {features.map((feature, index) => (
            <div key={index} className="feature-card">
              <div className="feature-icon">
                {feature.icon}
              </div>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
        
        <div className="explore-prompt">
          <p>Explore our featured books below</p>
          <div className="arrow-down" />
        </div>
      </div>

      <style jsx>{`
        .hero-section {
          position: relative;
          overflow: hidden;
          min-height: 600px;
          background: ${isDarkMode 
            ? 'var(--bg-primary)'
            : 'var(--bg-secondary)'};
          border-radius: 30px;
          color: var(--text-primary);
        }

        .hero-background {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          opacity: 0.25;
          background: ${isDarkMode 
            ? 'radial-gradient(circle at 20% 50%, rgba(99, 102, 241, 0.15) 0%, transparent 50%),\
               radial-gradient(circle at 80% 50%, rgba(99, 102, 241, 0.15) 0%, transparent 50%)'
            : 'radial-gradient(circle at 20% 50%, rgba(99, 102, 241, 0.2) 0%, transparent 50%),\
               radial-gradient(circle at 80% 50%, rgba(99, 102, 241, 0.2) 0%, transparent 50%)'};
        }

        .floating-book {
          position: absolute;
          width: 180px;
          height: 270px;
          background-size: cover;
          background-position: center;
          border-radius: 8px;
          box-shadow: ${isDarkMode 
            ? '0 20px 40px rgba(0,0,0,0.4), 0 0 20px rgba(99, 102, 241, 0.2)'
            : '0 20px 40px rgba(0,0,0,0.2), 0 0 20px rgba(99, 102, 241, 0.1)'};
          animation: float 6s ease-in-out infinite;
          opacity: 0.9;
          backdrop-filter: blur(2px);
          border: 1px solid ${isDarkMode 
            ? 'rgba(255, 255, 255, 0.1)'
            : 'rgba(0, 0, 0, 0.1)'};
        }

        .floating-book:nth-child(1) { top: 10%; left: 5%; }
        .floating-book:nth-child(2) { top: 15%; right: 8%; }
        .floating-book:nth-child(3) { bottom: 15%; left: 12%; }
        .floating-book:nth-child(4) { bottom: 20%; right: 15%; }
        .floating-book:nth-child(5) { top: 30%; left: 50%; transform: translateX(-50%); }
        .floating-book:nth-child(6) { top: 25%; left: 25%; }
        .floating-book:nth-child(7) { bottom: 30%; left: 35%; }
        .floating-book:nth-child(8) { top: 40%; right: 25%; }

        @keyframes float {
          0% { 
            transform: translateY(0) rotate(var(--rotation));
          }
          50% { 
            transform: translateY(-15px) rotate(calc(var(--rotation) + 1deg));
          }
          100% { 
            transform: translateY(0) rotate(var(--rotation));
          }
        }

        .hero-content {
          position: relative;
          z-index: 2;
          max-width: 1200px;
          margin: 0 auto;
        }

        .logo-container {
          text-align: center;
          margin-bottom: 2rem;
        }

        .hero-logo {
          max-width: 250px;
          height: auto;
          opacity: 0.9;
          transition: all 0.3s ease;
        }

        .hero-logo:hover {
          opacity: 1;
          transform: scale(1.02);
        }

        .hero-text {
          text-align: center;
          margin: 20px;
          margin-bottom: 4rem;
        }

        h1 {
          font-size: 4.5rem;
          margin-bottom: 1.5rem;
          font-weight: 800;
          background: ${isDarkMode 
            ? 'linear-gradient(to right, var(--text-primary) 0%, var(--text-secondary) 100%)'
            : 'linear-gradient(to right, var(--text-primary) 0%, var(--text-secondary) 100%)'};
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }

        .hero-subtitle {
          font-size: 1.4rem;
          opacity: 0.9;
          max-width: 700px;
          margin: 0 auto 2rem auto;
          line-height: 1.6;
        }

        .cta-buttons {
          display: flex;
          justify-content: center;
          margin-top: 2rem;
        }

        .primary-cta {
          width: auto;
          height: 36px;
          padding: 0 24px;
          font-size: 15px;
          background: var(--accent-color);
          border-radius: 12px;
          color: white;
          border: none;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.3s ease;
          box-shadow: var(--shadow-sm);
        }

        .primary-cta:active {
          transform: translateY(0);
          box-shadow: var(--shadow-sm);
        }

        .features-grid {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 2rem;
          margin-top: 3rem;
        }

        .feature-card {
          background: ${isDarkMode 
            ? 'var(--bg-secondary)'
            : 'var(--bg-primary)'};
          padding: 2rem;
          border-radius: 20px;
          backdrop-filter: blur(10px);
          border: 2px solid ${isDarkMode 
            ? 'var(--border-color)'
            : 'var(--border-color)'};
          position: relative;
          overflow: hidden;
          box-shadow: var(--shadow-sm);
        }

        .feature-card::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: ${isDarkMode 
            ? 'linear-gradient(135deg, rgba(99, 102, 241, 0.15) 0%, transparent 100%)'
            : 'linear-gradient(135deg, rgba(99, 102, 241, 0.1) 0%, transparent 100%)'};
          opacity: 1;
        }

        .feature-icon {
          font-size: 2rem;
          margin-bottom: 1.5rem;
          color: var(--accent-color);
          position: relative;
        }

        .feature-icon-svg {
          width: 2rem;
          height: 2rem;
          stroke-width: 1.5;
        }

        .feature-card h3 {
          font-size: 1.3rem;
          margin-bottom: 0.8rem;
          font-weight: 600;
          position: relative;
        }

        .feature-card p {
          opacity: 0.9;
          line-height: 1.6;
          font-size: 0.95rem;
          position: relative;
          color: var(--text-secondary);
        }

        .explore-prompt {
          text-align: center;
          margin-top: 4rem;
          color: var(--text-secondary);
          font-size: 1.1rem;
          opacity: 0.9;
        }

        .arrow-down {
          width: 20px;
          height: 20px;
          border-right: 2px solid var(--text-secondary);
          border-bottom: 2px solid var(--text-secondary);
          transform: rotate(45deg);
          margin: 1rem auto;
          opacity: 0.6;
        }

        @media (max-width: 1024px) {
          .features-grid {
            grid-template-columns: repeat(2, 1fr);
          }
          
          .floating-book {
            width: 150px;
            height: 225px;
          }
        }

        @media (max-width: 768px) {
          .hero-section {
            padding: 4rem 1rem;
          }

          h1 {
            font-size: 3rem;
          }

          .hero-subtitle {
            font-size: 1.2rem;
          }

          .features-grid {
            margin: 20px;
            grid-template-columns: 1fr;
          }

          .floating-book {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default HeroSection; 