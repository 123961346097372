import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookOpen, MessageCircle, ArrowLeft, ArrowRight } from 'react-feather';
import { authFetch, catchError } from '../utils';
import { toast } from 'react-toastify';
import { useTheme } from '../context/ThemeContext';

export default function FeaturedBookHero({ books, handleClick, user }) {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [purchasedBooks, setPurchasedBooks] = useState({});
  const [isTransitioning, setIsTransitioning] = useState(false);

  const currentBook = books[currentIndex];

  const handleNavigation = (direction) => {
    setIsTransitioning(true);
    setTimeout(() => {
      if (direction === 'next') {
        setCurrentIndex((prev) => (prev + 1) % books.length);
      } else {
        setCurrentIndex((prev) => (prev - 1 + books.length) % books.length);
      }
      setIsTransitioning(false);
    }, 300);
  };

  const handleAddBook = async (book) => {
    const [error, data] = await catchError(authFetch({
      path: `/book/${book._id}/add`,
      method: 'POST',
    }));
    if (data) {
      toast.success(`${book.title} Added`);
      setPurchasedBooks(prev => ({
        ...prev,
        [book._id]: true
      }));
      navigate(`/books/${book._id}`);
    }
    if (error) {
      toast.error(`${book.title} could not be added to library: ${error.message}`);
    }
  };

  if (!currentBook) return null;

  return (
    <div className={`featured-hero ${isDarkMode ? 'dark' : 'light'} ${isTransitioning ? 'transitioning' : ''}`}>
      <div className="featured-hero-background">
        <div className="featured-hero-backdrop" 
             style={{ backgroundImage: `url(${currentBook.image_url})` }} />
      </div>

      <div className="featured-hero-content">
        <div className="featured-hero-book">
          <img
            src={currentBook.image_url}
            alt={currentBook.title}
            className="featured-hero-image"
            onClick={() => handleClick(currentBook._id)}
          />
        </div>

        <div className="featured-hero-details">
          <div className="featured-hero-header">
            <h1>{currentBook.title}</h1>
            <h2>{currentBook.author}</h2>
            {currentBook.tagline && (
              <p className="featured-hero-tagline">{currentBook.tagline}</p>
            )}
          </div>

          <div className="featured-hero-metadata">
            {currentBook.ageGroup && (
              <div className="hero-metadata-item">
                <span className="metadata-label">Age Group</span>
                <span className="metadata-value">{currentBook.ageGroup}</span>
              </div>
            )}
            {currentBook.readingTime && (
              <div className="hero-metadata-item">
                <span className="metadata-label">Reading Time</span>
                <span className="metadata-value">{currentBook.readingTime} min</span>
              </div>
            )}
          </div>

          {currentBook.description && (
            <p className="featured-hero-description">{currentBook.description}</p>
          )}

          {currentBook.categories && currentBook.categories.length > 0 && (
            <div className="featured-hero-categories">
              {currentBook.categories.map((category, idx) => (
                <span key={idx} className="hero-category-tag">{category}</span>
              ))}
            </div>
          )}

          <div className="featured-hero-actions">
            {(currentBook.isPurchased || purchasedBooks[currentBook._id]) ? (
              <>
                <button 
                  className="hero-primary-action"
                  onClick={() => navigate(`/books/${currentBook._id}`)}
                >
                  <BookOpen size={20} />
                  <span>{currentBook.currentPage > 1 ? 'Continue Reading' : 'Start Reading'}</span>
                </button>
                <button 
                  className="hero-secondary-action"
                  onClick={() => navigate(`/books/${currentBook._id}#characters`)}
                >
                  <MessageCircle size={20} />
                  <span>Start Chatting</span>
                </button>
              </>
            ) : user?.email ? (
              <button 
                className="hero-primary-action"
                onClick={() => handleAddBook(currentBook)}
              >
                <BookOpen size={20} />
                <span>Add to Library</span>
              </button>
            ) : (
              <button 
                className="hero-primary-action"
                onClick={() => navigate('/login', { state: { from: window.location.pathname } })}
              >
                <BookOpen size={20} />
                <span>Sign in to Add</span>
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="featured-hero-navigation">
        <button 
          className="hero-nav-button prev"
          onClick={() => handleNavigation('prev')}
          disabled={books.length <= 1}
        >
          <ArrowLeft size={24} />
        </button>
        <div className="featured-hero-indicators">
          {books.map((_, idx) => (
            <button
              key={idx}
              className={`hero-indicator ${idx === currentIndex ? 'active' : ''}`}
              onClick={() => setCurrentIndex(idx)}
            />
          ))}
        </div>
        <button 
          className="hero-nav-button next"
          onClick={() => handleNavigation('next')}
          disabled={books.length <= 1}
        >
          <ArrowRight size={24} />
        </button>
      </div>
    </div>
  );
} 